import React, { useState } from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  Typography,
  Container,
  Grid,
  Box,
  Button,
  List,
  ListItem,
  TextField,
  FormControl,
} from '@material-ui/core';
import { navigate } from "gatsby";

import Logo from '../assets/images/logo-la-grande-ourse.inline.svg';
import LinkedInIcon from '../assets/icons/linkedIn.square.inline.svg';
import FacebookIcon from '../assets/icons/facebook.square.inline.svg';
import InstagramIcon from '../assets/icons/instagram.square.inline.svg';
import TwitterIcon from '../assets/icons/twitter.square.inline.svg';
import PatreonIcon from '../assets/icons/patreon.square.inline.svg';
import YoutubeIcon from '../assets/icons/youtube.square.inline.svg';

import HeartIcon from '../assets/icons/heart.inline.svg';

import ImagesLoader from '../components/imagesLoader';

const useStyles = makeStyles(theme => ({
  section: {
    // height: 677,
    backgroundColor: '#2e5bcc',
    color: '#ffffff',
  },
  container: {
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      padding: '0 100px',
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 3),
    },
  },
  logoWrapper: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 0),
    },
    padding: theme.spacing(8, 0),
  },
  logo: {
    fill: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
    width: 300,
  },
  textSection: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(5, 0),
    '& > *': {
      fontWeight: 600,
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      '& > *': {
        margin: theme.spacing(1.5, 0),
      },
    },
  },
  bottomTextSection: {
    backgroundColor: '#ffffff',
    color: '#000000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3, 0),
  },
  imageLogo: {
    width: 31,
    marginLeft: 10,
  },
  firstListElement: {
    fontWeight: 600,
    paddingBottom: theme.spacing(2),
  },
  iconsSection: {
    marginBottom: theme.spacing(3),
  },
  inputLabelPropsFocused: {
    color: 'transparent !important',
  },
  inputLabelProps: {
    color: '#505361',
  },
  inputProps: {
    color: '#000000',
  },
  button: {
    border: '1px solid white',
    borderRadius: 6,
    height: 40,
    marginLeft: 10,
  },
  formInput: {
    display: 'flex',
    marginBottom: 10,
  },
  oneLine: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexFlow: 'column',
    paddingBottom: '16',
  },
  footerLink: {
    fontSize: 18,
    fontWeight: 300,
    color: '#ffffff',
    textDecoration: 'none',
    paddingBottom: '16',
  },
  pointerLink: {
    cursor: 'pointer',
  },
}));

export default function Footer() {
  const classes = useStyles();
  const [shrink, setShrink] = useState(true);
  return (
    <section className={classes.section}>
      <Container className={classes.container}>
        <div className={classes.logoWrapper}>
          <Logo className={classes.logo} />
        </div>
        <Grid container justify="center" alignItems="flex-start" className={classes.gridContainer}>
          <Grid item xs={12} md={2}>
            <List>
              <ListItem>
                <Typography variant="h3" className={classes.firstListElement}>
                  {/* <Box fontWeight="600">Site</Box> */}
                  Site
                </Typography>
              </ListItem>
              <ListItem className={classes.oneLine}><a className={classes.footerLink} href="https://lagrandeourse.design/agence-la-grande-ourse/">L'agence</a></ListItem>
              <ListItem className={classes.oneLine}><a className={classes.footerLink} href="https://lagrandeourse.design/expertises/">Expertises</a></ListItem>
              <ListItem className={classes.oneLine}><a className={classes.footerLink} href="https://www.lagrandeourse.design/realisations/">Réalisations</a></ListItem>
              <ListItem className={classes.oneLine}><a className={classes.footerLink} href="https://lagrandeourse.design/outils/">Outils</a></ListItem>
              <ListItem className={classes.oneLine}><a className={classes.footerLink} href="https://lagrandeourse.design/blog/">Blog</a></ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={2}>
            <List>
              <ListItem>
                <Typography variant="h3" className={classes.firstListElement}>
                  Services
                </Typography>
              </ListItem>
              <ListItem className={classes.oneLine}><a className={classes.footerLink} href="https://lagrandeourse.design/devenez-testeur/">Devenez testeur</a></ListItem>
              <ListItem className={classes.oneLine}><a className={classes.footerLink} href="https://lagrandeourse.design/audit-ux/">Audit UX</a></ListItem>
              <ListItem className={classes.oneLine}><a className={classes.footerLink} href="https://lagrandeourse.design/un-poil-d-ux/">Nos podcasts</a></ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={2}>
            <List>
              <ListItem>
                <Typography variant="h3" className={classes.firstListElement}>
                  Contact
                </Typography>
              </ListItem>
              <ListItem className={classes.oneLine}><a className={classes.footerLink} href="https://lagrandeourse.design/prendre-contact/">Nous contacter</a></ListItem>
              <ListItem className={classes.oneLine}><a className={classes.footerLink} href="https://lagrandeourse.design/nous-rejoindre/">Nous rejoindre</a></ListItem>
              <ListItem className={classes.oneLine}><a className={classes.footerLink} href="https://lagrandeourse.design/freelance/">Freelance</a></ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={3}>
            <List>
              <ListItem>
                <Typography variant="h3" className={classes.firstListElement}>
                  Outils
                </Typography>
              </ListItem>
              <ListItem className={classes.oneLine}><a className={classes.footerLink} href="https://lagrandeourse.design/ressources//">Ressources</a></ListItem>
              <ListItem className={classes.oneLine}><a className={classes.footerLink} href="https://calcul-gratifications.lagrandeourse.design/">Calcul gratification</a></ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={3}>
            <List>
              <ListItem>
                <Typography variant="h3" gutterBottom>
                  <Box fontWeight={600}>Suivez-nous</Box>
                </Typography>
              </ListItem>
              <div className={classes.iconsSection}>
                <LinkedInIcon className={classes.customIcon}  onClick={()=>{navigate("https://www.linkedin.com/company/agence-la-grande-ourse/")}} />
                <FacebookIcon className={classes.customIcon}  onClick={()=>{navigate("https://www.facebook.com/lagrandeourse.design/")}} />
                <InstagramIcon className={classes.customIcon}  onClick={()=>{navigate("https://www.instagram.com/_lagrandeourse/")}} />
                <TwitterIcon className={classes.customIcon}  onClick={()=>{navigate("https://twitter.com/_lagrandeourse")}} />
                <PatreonIcon className={classes.customIcon}  onClick={()=>{navigate("https://www.pinterest.fr/lagrandeourse/")}} />
                <YoutubeIcon className={classes.customIcon}  onClick={()=>{navigate("https://www.youtube.com/channel/UCFMUapEQ0WcdJKCI50sbqWg")}} />
              </div>
            </List>
          </Grid>
        </Grid>
        <div className={classes.textSection}>
          <Typography variant="h3" component="div" className={classes.pointerLink} onClick={()=>{navigate("https://lagrandeourse.design/conditions-juridiques/mentions-legales/")}}>
            Mentions légales
          </Typography>
          <Typography variant="h3" component="div" className={classes.pointerLink}  onClick={()=>{navigate("https://lagrandeourse.design/conditions-juridiques/charte-cookies/")}}>
            Charte des cookies
          </Typography>
          <Typography variant="h3" component="div" className={classes.pointerLink}  onClick={()=>{navigate("https://lagrandeourse.design/conditions-juridiques/cgv/")}}>
            CGV/CGU
          </Typography>
        </div>
      </Container>
      <div className={classes.bottomTextSection}>
        <Typography variant="subtitle1">
          <Box fontWeight={400}>
            @2021 - Design with <HeartIcon /> by <b>La grande Ourse</b>
          </Box>
        </Typography>
        <ImagesLoader imageName="imageLogo" className={classes.imageLogo} />
      </div>
    </section>
  );
}
