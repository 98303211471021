/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Hidden,
  ButtonBase,
  List,
  ListItem,
  Link,
  Container,
} from '@material-ui/core';
import { navigate } from "gatsby";

import Logo from '../assets/images/logo-la-grande-ourse.inline.svg';
import MobileMenuBackground from '../assets/images/mobileMenu-background.svg';
import MenuIcon from '../assets/icons/menuIcon.inline.svg';
import CloseIcon from '../assets/icons/closeIcon.inline.svg';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: 'relative',
  },
  toolbar: {
    [theme.breakpoints.up('md')]: {
      height: 103,
    },
    padding: 0,
  },

  sectionL: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  sectionR: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  sectionLink: {
    fontSize: 18,
    fontWeight: 300,
    color: '#505361',
    textDecoration: 'none',
    paddingLeft: 0,
    paddingRight: 30,
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      width: 150,
    },
    fill: '#002AAF',
    width: 226,
  },
  logoHeader: {
    fill: '#002AAF',
    left: 40,
    top: 23,
    width: '50%',
    position: 'absolute',
  },
  h2: {
    fontWeight: 300,
  },
  mobileMenu: {
    backgroundColor: '#ebf0fb',
    position: 'absolute',
    width: '100%',
    zIndex: 1000,
    overflow: 'hidden',
    backgroundImage: `url(${MobileMenuBackground})`,
    backgroundSize: 'cover',
  },
  mobileMenuPanel: {
    padding: theme.spacing(5, 5, 20, 5),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexFlow: 'column',
  },
  sectionLinkMobile: {
    fontSize: 20,
    fontWeight: 300,
    color: '#505361',
    textDecoration: 'none',
    paddingBottom: 20,
  },
  iconButton: {
     //marginLeft: 30,
  },
  menuButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
  },
}));

const pages = [
  { text: 'L’agence', link: 'https://lagrandeourse.design/agence-la-grande-ourse/' },
  { text: 'Expertises', link: '#2' },
  { text: 'Réalisations', link: '#4' },
  { text: 'Outils', link: '#5' },
  { text: 'Blog', link: '#6' },
];

const mobilePages = [
  { text: 'L’agence', link: 'https://lagrandeourse.design/agence-la-grande-ourse/' },
  { text: 'Expertises', link: '#2' },
  { text: 'Réalisations', link: '#4' },
  { text: 'Outils', link: '#5' },
  { text: 'Blog', link: '#6' },
];

export default function Header() {
  const classes = useStyles();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(false);

  const handleMenuButtonClick = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleNavigation = ({ link }) => {
    setActiveLink(link);
  };

  return (
    <div className={classes.root}>
      {isMobileMenuOpen && (
        <Hidden lgUp>
          <div className={classes.mobileMenu}>
            <List component="nav">
              <ListItem>
                <a alt="Accueil La Grande Ourse" href="https://lagrandeourse.design">
                  <Logo className={classes.logoHeader} />
                </a>
              </ListItem>
              <ListItem>
                <IconButton className={classes.menuButton} onClick={handleMenuButtonClick}>
                  <CloseIcon className={classes.iconButton} />
                </IconButton>
              </ListItem>
              <div className={classes.mobileMenuPanel}>
                <a className={classes.sectionLinkMobile} href="https://lagrandeourse.design/agence-la-grande-ourse/">L'agence</a>
                <a className={classes.sectionLinkMobile} href="https://lagrandeourse.design/expertises/">Expertises</a>
                <a className={classes.sectionLinkMobile} href="https://www.lagrandeourse.design/realisations/">Réalisations</a>
                <a className={classes.sectionLinkMobile} href="https://lagrandeourse.design/outils/">Outils</a>
                <a className={classes.sectionLinkMobile} href="https://lagrandeourse.design/blog/">Blog</a>
                <a className={classes.sectionLinkMobile} href="https://lagrandeourse.design/prendre-contact/">Prendre contact</a>
              </div>
            </List>
          </div>
        </Hidden>
      )}
      <Container>
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar className={classes.toolbar}>
            <Hidden mdUp>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                className={classes.iconButton}
                onClick={handleMenuButtonClick}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <div className={classes.sectionL}>
               <a alt="Accueil La Grande Ourse" href="https://lagrandeourse.design"><Logo className={classes.logo} /></a>
            </div>
            <Hidden smDown>
              <div className={classes.sectionR}>
                <a className={classes.sectionLink} href="https://lagrandeourse.design/agence-la-grande-ourse/">L'agence</a>
                <a className={classes.sectionLink} href="https://lagrandeourse.design/expertises/">Expertises</a>
                <a className={classes.sectionLink} href="https://www.lagrandeourse.design/realisations/">Réalisations</a>
                <a className={classes.sectionLink} href="https://lagrandeourse.design/outils/">Outils</a>
                <a className={classes.sectionLink} href="https://lagrandeourse.design/blog/">Blog</a>
                <Button color="primary" variant="contained" size="large" onClick={()=>{navigate("https://lagrandeourse.design/prendre-contact/")}}>
                  Prendre contact
                </Button>
              </div>
            </Hidden>
          </Toolbar>
        </AppBar>
      </Container>
    </div>
  );
}
