const priceCalculator = [
  // with animator
  [
    // remote
    [
      10, // 10 min
      15, // 20 min
      25, // 30 min
      30, // 40 min
      35, // 50 min
      40, // 1h
      50, // 1h30
      90, // 2h
      100, // 2h30
      150, // 3h
      160, // 3h30
      180, // 4h
      200, // 4h30
      240, // 5h
      300, // 1 jour
    ],
    // attendant on site
    [
      30, // 10 min
      35, // 20 min
      45, // 30 min
      60, // 40 min
      65, // 50 min
      70, // 1h
      80, // 1h30
      120, // 2h
      140, // 2h30
      190, // 3h
      200, // 3h30
      220, // 4h
      240, // 4h30
      280, // 5h
      340, // 1 jour
    ],
    // attendant via remote
    [
      35, // 10 min
      40, // 20 min
      50, // 30 min
      65, // 40 min
      70, // 50 min
      75, // 1h
      85, // 1h30
      125, // 2h
      145, // 2h30
      195, // 3h
      205, // 3h30
      225, // 4h
      245, // 4h30
      285, // 5h
      345, // 1 jour
    ],
  ],
  // without animator
  [
    // automatic
    [
      1, // 10 min
      7, // 20 min
      20, // 30 min
      25, // 40 min
      30, // 50 min
      40, // 1h
      60, // 1h30
      80, // 2h
      100, // 3h
      130, // 4h
      150, // 1j
      310, // 2J
      400, // 1 semaine
      500, // 2 semaines
      800, // 3 semaines
    ],
    // questionary
    [
      1, // 10 min
      7, // 20 min
      15, // 30 min
      20, // 40 min
      30, // 50 min
      35, // 1h
      55, // 1h30
      70, // 2h
      95, // 3h
      120, // 4h
      140, // 1j
      280, // 2J
      375, // 1 semaine
      465, // 2 semaines
      840, // 3 semaines
    ],
    // online exercise
    [
      1, // 10 min
      7, // 20 min
      15, // 30 min
      20, // 40 min
      30, // 50 min
      40, // 1h
      55, // 1h30
      70, // 2h
      95, // 3h
      120, // 4h
      150, // 1j
      295, // 2J
      390, // 1 semaine
      490, // 2 semaines
      880, // 3 semaines
    ],
  ],
];

export const attendantsTypeMultiplicators = [0.85, 0.95, 1.0, 1.2, 1.8, 2.5, 3.5, 4.0, 5.0, 6.0, 10.0, 1111111.0];

export const hurryMultiplicators = [0.85, 0.9, 1.0, 1.5, 2.0, 2.3];

export default priceCalculator;
